<template>
  <div>
    <v-card>
      <v-card-actions>
        <v-text-field
          v-model="newMessage"
          label="Type your message..."
          @keyup.enter="handleSend"
          :disabled="isLoading"
        ></v-text-field>
        <v-select
          v-if="room === 'private'"
          v-model="selectedReceiver"
          :items="users"
          item-text="name"
          item-value="id"
          label="Select receiver"
          dense
        ></v-select>
        <v-btn icon @click="handleSend" :disabled="isLoading">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-list>
          <v-list-item v-for="message in reversedMessages" :key="message.id">
            <v-list-item-content>
              <v-list-item-title v-html="formatMessage(message)"></v-list-item-title>
              <v-list-item-subtitle v-if="shouldTruncate(message.content)">
                {{ truncateMessage(message.content) }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text>Read more</v-btn>
                  </template>
                  <span>{{ message.content }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{ message.content }}</v-list-item-subtitle>
              <v-list-item-subtitle class="message-time">{{ formatTime(message.created_at) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    room: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newMessage: "",
      selectedReceiver: null,
    };
  },
  computed: {
    reversedMessages() {
      return this.messages.slice().reverse();
    },
  },
  methods: {
    handleSend() {
      if (this.newMessage.trim() === "") return;
      const receiverId = this.room === 'private' ? this.selectedReceiver : null;
      this.$emit("send-message", this.newMessage, this.room, receiverId);
      this.newMessage = "";
    },
    formatMessage(message) {
      let sender = this.getUserById(message.sender_id);
      let receiver = this.getUserById(message.receiver_id);
      let senderRole = this.getUserRole(sender.role_id);
      let receiverRole = this.getUserRole(receiver.role_id);

      if (this.room === 'general') {
        return `${sender.name} (${this.boldText(senderRole)})`;
      } else if (this.room === 'private') {
        return `${sender.name} (${this.boldText(senderRole)}) to ${receiver.name} (${this.boldText(receiverRole)})`;
      }
    },
    boldText(text) {
      return `<b>${text}</b>`;
    },
    formatTime(dateTime) {
      const date = new Date(dateTime);
      const options = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensure using 12-hour format
      };
      const formattedTime = date.toLocaleDateString('en-GB', options).replace(/,/g, '');

      return `${formattedTime}`;
    },
    getUserById(id) {
      const user = this.users.find(user => user.id == id); // Use loose comparison
      return user ? user : { name: 'Unknown', role_id: null };
    },
    getUserRole(roleId) {
      switch (roleId) {
        case 1:
        return 'Admin';
        case 2:
          return 'Admin';
        case 3:
          return 'Staff';
        case 4:
          return 'Parent';
        case 5:
          return 'Guardian';
        case 6:
          return 'Participant';
        default:
          return 'Unknown';
      }
    },
    shouldTruncate(content) {
      // Adjust the threshold as per your design needs
      return content.length > 100; // Truncate if content length exceeds 100 characters
    },
    truncateMessage(content) {
      // Adjust the truncation length as per your design needs
      return content.slice(0, 100) + '...'; // Truncate content to 100 characters and add ellipsis
    },
  },
};
</script>

<style scoped>
.message-time {
  font-style: italic;
  font-size: 0.8rem;
  color: #888;
}
</style>
