<template>
  <div>
    <v-card>
      <v-card-title>
        <v-icon color="primary">mdi-chat</v-icon>
        <span class="pl-2">Chat</span>
      </v-card-title>
      <v-tabs v-model="active_tab" show-arrows class="my-2">
        <v-tab v-for="tab in tabs" :key="tab.idx">
          <v-icon size="20" class="me-3">{{ tab.icon }}</v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="active_tab">
        <!-- General Chat -->
        <v-tab-item>
          <chat-room
            :messages="generalMessages"
            :is-loading="isLoading"
            room="general"
            :users="users"
            @send-message="sendMessage"
          ></chat-room>
        </v-tab-item>

        <!-- Private Chat -->
        <v-tab-item>
          <chat-room
            :messages="privateMessages"
            :is-loading="isLoading"
            room="private"
            :users="users"
            @send-message="sendMessage"
          ></chat-room>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiChat, mdiMessageTextOutline } from "@mdi/js";
import axios from "axios";
import ChatRoom from "./chat-room.vue"; // Adjust according to your file structure

export default {
  components: {
    ChatRoom,
  },
  data() {
    return {
      isLoading: false,
      generalMessages: [],
      privateMessages: [],
      users: [], // This will hold the users data
      tabs: [
        { idx: 0, title: "General", icon: mdiChat },
        { idx: 1, title: "Private", icon: mdiMessageTextOutline },
      ],
      active_tab: 0,
    };
  },
  watch: {
    active_tab(newVal, oldVal) {
      localStorage.tabIdxChat = newVal;
    },
  },
  mounted() {
    this.active_tab = parseInt(localStorage.tabIdxChat, 10) || 0;
    this.loadMessages();
    this.loadUsers(); // Load the users when the component mounts
  },
  methods: {
    loadMessages() {
      this.isLoading = true;
      axios
        .get(`/chat/messages`) // Adjust to match the correct Laravel route
        .then((response) => {
          this.generalMessages = response.data.general;
          this.privateMessages = response.data.private;
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
          this.$notify({
            title: "Error",
            text: "Error while retrieving messages",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadUsers() {
      axios
        .get(`/chat/users`) // Adjust to match the correct Laravel route
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
          this.$notify({
            title: "Error",
            text: "Error while retrieving users",
            type: "error",
          });
        });
    },
    sendMessage(message, room, receiverId) {
      this.isLoading = true;
      const requestData = {
        content: message,
        room: room,
        receiver_id: receiverId,
      };
      console.log('Request Data:', requestData); // Add this line for debugging
      axios
        .post(`/chat/messages/send`, requestData)
        .then((response) => {
          const newMessage = response.data;
          if (room === "general") {
            this.generalMessages.unshift(newMessage); // Add new message to the top of the list
          } else {
            this.privateMessages.unshift(newMessage); // Add new message to the top of the list
          }
          this.$notify({
            title: "Success",
            text: "Message sent",
            type: "success",
          });
        })
        .catch((error) => {
          console.error("Error sending message:", error);
          this.$notify({
            title: "Error",
            text: "Error while sending message",
            type: "error",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
  cursor: pointer;
  background: rgba($primary-shade--light, 0.15) !important;
}
</style>
